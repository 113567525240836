.side-nav-pastelOrange {
  border-right: none;
  background-color: scale-color($pastelOrange-primary-light, $lightness: 85%);
  box-shadow: $pastelOrange-shadow-medium;

  .side-nav-item-pastelOrange {
    &:hover {
      background-color: scale-color(
        $pastelOrange-primary-light,
        $lightness: 65%
      );
    }
  }

  .side-nav-item-text-pastelOrange {
    color: $pastelOrange-gray-700;
  }
}
