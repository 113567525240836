.chat-box {
	z-index: 10;
	position: fixed;
	right: 0;
	top: calc($main-nav-bar-height);
	height: calc(100vh - $main-nav-bar-height);
	width: 350px;
	background-color: white;
	box-shadow: $shadow-dark;
	text-align: left;
	display: flex;
	flex-direction: column;
}

.chat-box-header {
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid $grey-20;
}

.message-input-container {
	margin-top: auto;
	margin-left: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	border: 1px solid $grey-20;
	border-radius: 4px;
	padding: 8px;
	width: calc(100% - 32px);
	color: $gray-900;
	font-size: 12px;
	background-color: $white;
	display: flex;
	align-items: center;
	min-height: 36px;
	&:hover {
		outline: 2px solid $accent-30;
	}
}

.message-input-container-focused {
	outline: 2px solid $accent-40;
}

.message-input {
	padding-left: 8px;
	width: 95%;
	border: none;
	font-size: 14px;
	min-height: 20px;
	resize: none;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: $grey-30;
	}
}

.chat-box-messages-container {
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	height: calc(100% - 150px);
	overflow-y: auto;
}

.chat-box-message {
	font-size: 14px;
	padding: 12px 12px 0px 12px;
	border-radius: 8px;
	width: fit-content;
}

.chat-box-assistant-message {
	background-color: $grey-20;
}

.chat-box-user-message {
	margin-left: auto;
	background-color: $accent-color;
	color: white;
}
