.declarations {
	padding: 16px;
	padding-left: 20px;
	margin-left: 90px; // 90px = width of sidebar
	margin-top: 60px; // 60px = height of navbar
}

.declarations-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 16px 8px;
	text-align: left;
}

.declarations-title {
	font-size: 22px;
	text-align: start;
	font-weight: 500;
}

.declaration-item {
	text-align: left;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	cursor: pointer;
	position: relative;
}

.declaration-item-selected {
	background-color: $accent-surface;
}

.declaration-item-selected-highlight {
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: $accent-30;
	width: 4px;
}

.declaration-item-border {
	box-shadow: $shadow-light;
}

.declaration-item-title {
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
}

.declaration-item-subtitle {
	color: $gray-700;
}

.tabs-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 40px;
	margin-bottom: 12px;
	border-bottom: 1px solid $grey-30;
}

.tab {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 14px;
	height: 40px;
	user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.tab-selected {
	font-weight: 600;
	color: $accent-color;
}

.tab-selection-highlighter {
	width: 100%;
	height: 2px;
	background-color: $accent-color;
	position: absolute;
	bottom: -1px;
	left: 0px;
}

.declaration-action-buttons-container {
	width: fit-content;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.declarations-preview-container {
	margin: 24px 16px 0;
}

.declarations-loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 120px;
}

.expandable-list {
	width: 100%;
	max-width: 600px;
	overflow: hidden;
	transition: 0.3s;
	box-shadow: $shadow-dark;
}

.expandable-list-header {
	display: flex;
	align-items: center;
	user-select: none;
	padding: 16px;
	font-weight: 600;
	font-size: 16px;
}

.expand-list-button {
	width: fit-content;
	margin-left: auto;
	border-radius: 50%;
	font-size: 20px;
	width: 25px;
	height: 25px;
	cursor: pointer;
	&:hover {
		background-color: #f0f0f0;
	}
}

.expandable-list-item {
	width: 100%;
	padding: 8px 16px 8px 16px;
	display: flex;
	align-items: center;
	font-size: 12px;
	text-align: left;
	border-bottom: 1px solid rgb(236, 236, 236);
}

.expandable-list-item-read {
	width: 100%;
	padding: 8px 16px 8px 16px;
	display: flex;
	align-items: center;
	font-size: 12px;
	text-align: left;
	border-bottom: 1px solid rgb(236, 236, 236);
	color: #a0a0a0;
}

.warning-text {
	color: grey;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 12px;
	text-align: start;
}

.declaration-status-tag {
	color: $grey-50;
	background-color: $accent-10;
	font-size: 12px;
	padding: 2px 4px 2px 4px;
	border-radius: 4px;
	width: fit-content;
	text-align: center;
}

.declaration-status-tag-accepted,
.declaration-status-tag-cleared {
	background-color: $accent-20;
	color: $accent-70;
}

.declaration-status-tag-rejected,
.declaration-status-tag-cancelled {
	color: $red-80;
	background-color: $red-20;
}

.declaration-status-tag-submitted,
.declaration-status-tag-pendingcancel {
	background-color: $yellow-20;
	color: $yellow-80;
}

.items-list {
	width: 100px;
	background-color: $accent-surface;
}

.item {
	position: relative;
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 20px;
	padding-bottom: 20px;
	cursor: pointer;
}

.item-selection-highlighter {
	width: 4px;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: $accent-30;
}

.item-selected {
	background-color: $accent-20;
}

.item-title {
	font-size: 14px;
	font-weight: 500;
}

.validation-errors-container {
	margin-top: 32px;
	border-radius: 12px;
	background-color: #faf2f2;
	border: 1px solid #e36464;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 40px;
	padding-right: 40px;
	text-align: left;
}

.validation-errors-title {
	font-weight: 700;
	font-size: 16px;
	color: $red-80;
}

.error-list-item {
	display: flex;
	color: $grey-70;
	padding-top: 8px;
	padding-bottom: 8px;
	border-bottom: 1px solid $grey-20;
}
