.side-nav {
	z-index: 4;
	height: 100vh;
	position: fixed;
	display: flex;
	flex-direction: column;
	left: 0;
	top: 0;
	width: $side-nav-bar-width;
	padding-top: 24px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: white;
	color: $primary-color;
	transition: 0.3s;

	.side-nav-item {
		cursor: pointer;
		align-items: center;
		display: flex;
		padding-top: 14px;
		padding-bottom: 14px;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 8px;
		margin-top: 18px;
		margin-bottom: 18px;
		text-align: left;
		gap: 12px;

		&:hover {
			background-color: $accent-surface;
		}

		.side-nav-item-text {
			font-size: 14px;
			// margin-top: -2px;
		}

		.side-nav-item-icon {
			font-size: 24px;
			min-width: 24px;
		}

		// svg {
		// 	width: 22px;
		// 	height: 22px;
		// }

		// .side-nav-item-text {
		// 	margin-top: 6px;
		// 	color: $primary-color;
		// 	font-size: 12px;
		// 	line-height: 14px;
		// }
	}

	.side-nav-item-active {
		background-color: $accent-10;

		.side-nav-item-text {
			color: $accent-color;
		}

		.side-nav-item-icon {
			color: $accent-color;
		}
	}
}

.side-nav-collapsed {
	width: $side-nav-bar-width-collapsed;
	align-items: center;
}



.expand-button-outter {
	position: absolute;
	top: 32px;
	right: -16px;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	cursor: pointer;
	color: white;
}

.expand-button-inner {
	width: 26px;
	height: 26px;
	background-color: #2d5e41;
	border-radius: 50%;
}

.secondary-expand-button-inner {
	background-color: $accent-30;
}
