// Base styles

body {
	background-color: $accent-10;
}

.app-container {
	min-height: 100vh; // -60px = the height of the navbar
}

.custom-badge {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: $accent-40;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -12px;
	top: -6px;
	font-size: 12px;
	font-weight: 400;
	z-index: 2;
	user-select: none;
}

.custom-badge-compact {
	width: 12px;
	height: 12px;
	right: -4px;
	top: -3px;
}

.drawer {
	min-height: 80vh;
	width: 320px;
	border-right: 1px solid $accent-20;
	position: relative;
	transition: 0.3s;
}

.drawer-collapsed {
	width: 64px;
	min-width: 64px;
	background-color: $grey-10;
}

.drawer-header {
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	width: 100%;
	text-align: left;
	padding-right: 20px;
	padding-left: 20px;
}

.drawer-title {
	font-size: 20px;
}

.list-item {
	text-align: left;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	cursor: pointer;
	position: relative;
}

.list-item-selected {
	background-color: $accent-surface;
}

.list-item-selected-highlight {
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: $accent-30;
	width: 4px;
}

.list-item-border {
	box-shadow: $shadow-light;
}

.list-item-title {
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
	text-align: left;
	max-width: 95%;
	white-space: nowrap;
	word-break: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
}

.list-item-subtitle {
	color: $grey-50;
	text-align: left;
	word-break: break-word;
}

.empty-page-message-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 80px;
}

.empty-page-text {
	font-size: 16px;
	font-weight: 500;
	color: $grey-30;
	text-align: center;
}

.card {
	flex-grow: 1;
	background-color: white;
	border-radius: 12px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
	padding-right: 20px;
	color: $grey-70;
	text-align: left;
	border: none;
	color: $grey-70;
}

.track-outter {
	background-color: $accent-20;
	border-radius: 4px;
	overflow: hidden;
	height: 4px;
	width: 100%;
	display: flex;
}

.track-inner {
	height: 100%;
	background-color: $accent-color;
}

.track-label {
	font-size: 14px;
}

.track-value {
	font-weight: 500;
	font-size: 16px;
	width: 80px;
}

.track-left {
	color: $grey-40;
	width: 80px;
	font-weight: 500;
	font-size: 16px;
}

.custom-modal {
	min-width: 100px !important;
	max-height: 95vh;
	height: fit-content;
	width: fit-content;
	text-align: left;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 40px;
	border-radius: 8px;
	background-color: white;
	overflow-y: auto;
}

.custom-modal-backdrop {
	z-index: 10;
	transition: 0.15s linear;
	top: 0px;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background-color: #00000a5a;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-label {
	font-size: 20px;
	font-weight: 600;
}

.confirm-modal-title {
	margin-top: 12px;
	font-size: 16px;
	font-weight: 500;
}

.confirm-modal-description {
	margin-top: 12px;
	font-size: 14px;
}

.tooltip-container {
	font-size: 14px;
	color: $grey-60;
	padding: 12px;
	border-radius: 8px 8px 8px 0px;
	box-shadow: 0px 4px 10px 0px #2b313c1c;
	background-color: $accent-surface;
	margin-left: 8px;
	margin-bottom: 12px;
	max-width: 240px;
}

.tooltip-container-compact {
	font-size: 12px;
	padding: 8px;
}

.overlay-body {
	z-index: 4;
	width: 280px;
	box-shadow: 0px 4px 24px 0px #2b313c40;
	border-radius: 8px;
	padding: 12px 20px 12px 20px;
	background-color: white;
}

.overlay-label {
	font-size: 14px;
	font-weight: 600;
}

.secondary-text {
	color: $grey-50;
}

.popover-option {
	color: $grey-60;
	font-size: 14px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 20px;
	padding-right: 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.popover-option:hover {
	background-color: $grey-10;
}
.popover-option:active {
	background-color: $accent-20;
}

.popover-option-disabled {
	opacity: 0.5;
	cursor: default;
	pointer-events: none;
}

.notification-request-popup {
	position: absolute;
	z-index: 10;
	top: 32px;
	left: 32px;
	background-color: white;
	min-height: 124px;
	width: 500px;
	box-shadow: 0px 4px 24px 0px rgba(43, 49, 60, 0.25);
	border-radius: 8px;
	padding: 20px;
	text-align: left;
}
