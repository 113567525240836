.search-input-container {
	border: 1px solid $grey-20;
	border-radius: 4px;
	padding: 8px;
	width: 250px;
	color: $gray-900;
	font-size: 12px;
	background-color: $white;
	display: flex;
	align-items: center;
	height: 36px;
	
	&:hover {
		outline: 2px solid $accent-30;
	}
	
}

.search-input-container-focused {
	outline: 2px solid $accent-40;

}

.search-input {
	padding-left: 8px;
	width: 95%;
	border: none;
	font-size: 14px;
	height: 20px;

	&:focus {
		outline: none;
	}
	
	&::placeholder {
		color: $grey-30;
	}
}
