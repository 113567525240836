.side-nav-pastelGreen {
  border-right: none;
  background-color: scale-color($pastelGreen-primary-light, $lightness: 85%);
  box-shadow: $pastelGreen-shadow-medium;

  .side-nav-item-pastelGreen {
    &:hover {
      background-color: scale-color(
        $pastelGreen-primary-light,
        $lightness: 65%
      );
    }
  }

  .side-nav-item-text-pastelGreen {
    color: $pastelGreen-gray-700;
  }
}
