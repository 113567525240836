.file-list-item {
	display: flex;
	align-items: center;
	padding: 8px;
	box-sizing: border-box;
	border: 1px solid transparent;
	position: relative;
	text-align: left;
}

.file-list-item-selected {
	background-color: $accent-10;
	border: 1px solid $accent-30;
	border-radius: 4px;
}

.file-list-item-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.file-list-item-image {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 4px;
	margin-right: 12px;
	border: 1px solid $grey-20;
}

.file-list-item-text {
	font-size: 12px;
	display: flex;
	flex-direction: column;
	gap: 2px;
	width: calc(100% - 72px);
}

.file-list-item-title {
	font-size: 14px;
	max-width: 70%;
	white-space: nowrap;
	word-break: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
}

.file-list-item-description {
	max-width: 70%;
	white-space: nowrap;
	word-break: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
}

.file-list-item-check-circle {
	color: $accent-40;
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.file-associated-dec-title {
	font-weight: 700;
	font-size: 16px;
	color: $accent-color;
	text-align: left;
}

.file-associated-dec {
	background-color: $grey-10;
	padding: 16px 20px 16px 20px;
	border-radius: 8px;
	margin-top: 4px;
	margin-bottom: 4px;
	cursor: pointer;
}