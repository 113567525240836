body {
  color: #292D33;
  margin: 0;
  font-family: Roboto, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.halfScreenModal {
  max-width: 50vw;
  width: 50vw;
  max-height: 50vh;
  overflow: "scroll";
}

.hover {
  cursor: pointer;
}

.page-link {
  cursor: pointer;
  user-select: none;
}

.grid-sizer,
.grid-item {
  width: 50%;
  padding-top: 6px;
  padding-bottom: 6px;
}

.grid {
  max-width: 100%;
  margin: 0 auto;
}

/* 2 columns wide */
.grid-item--width2 {
  width: 100%;
}
.grid:after {
  content: "";
  display: block;
  clear: both;
}
