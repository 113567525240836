.requestedAdditionalProceedureMultiline {
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(116, 116, 116);
  margin-top: 8px;
  padding: 12px;
}

.typeCodeTaxTypesMultiline {
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(116, 116, 116);
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px;
}


