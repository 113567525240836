.gvms {
  margin-left: 85px;
  padding: 16px;
  padding-top: 0px;
  margin-top: 60px;
}

.gvms-section {
  padding: 16px;
  text-align: left;
}

.gvms-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}
