// Scss variables (colors etc...)
//
// Custom Color gradients
//
// Color system
//
$pastelOrange-white: #fff !default;
$pastelOrange-gray-100: #f8f9fa !default;
$pastelOrange-gray-200: #e9ecef !default;
$pastelOrange-gray-300: #dee2e6 !default;
$pastelOrange-gray-400: #ced4da !default;
$pastelOrange-gray-500: #adb5bd !default;
$pastelOrange-gray-600: #6c757d !default;
$pastelOrange-gray-700: #495057 !default;
$pastelOrange-gray-800: #343a40 !default;
$pastelOrange-gray-900: #212529 !default;
$pastelOrange-black: #000 !default;
//
$pastelOrange-blue: #aec6cf !default;
$pastelOrange-indigo: #8686af !default;
$pastelOrange-purple: #b19cd9 !default;
$pastelOrange-pink: #ffd1dc !default;
$pastelOrange-red: #ff7770 !default;
$pastelOrange-orange: #ffb347 !default;
$pastelOrange-yellow: #fdfd96 !default;
$pastelOrange-green: #77dd77 !default;
$pastelOrange-teal: #63b7b7 !default;
$pastelOrange-cyan: #b2d8d8 !default;
// primary
$pastelOrange-primary: #ffc26b !default;
$pastelOrange-primary-dark: $pastelOrange-orange !default;
$pastelOrange-primary-light: #ffd9a3 !default;
// secondary
$pastelOrange-secondary: #cfbfff !default;
$pastelOrange-secondary-dark: #b5a5e5 !default;
$pastelOrange-secondary-light: #e4dbff !default;
//
$pastelOrange-success: $pastelOrange-green !default;
$pastelOrange-info: $pastelOrange-cyan !default;
$pastelOrange-warning: $pastelOrange-yellow !default;
$pastelOrange-danger: $pastelOrange-red !default;
$pastelOrange-light: $pastelOrange-gray-100 !default;
$pastelOrange-dark: $pastelOrange-gray-900 !default;
//
$pastelOrange-body-bg: $pastelOrange-white !default;
$pastelOrange-body-color: $pastelOrange-gray-900 !default;
//
// shadow
//
$pastelOrange-shadow-light: $pastelOrange-gray-200 0px 1px 3px 0px;
$pastelOrange-shadow-medium: $pastelOrange-gray-300 0px 1px 3px 0px;
$pastelOrange-shadow-dark: $pastelOrange-gray-400 0px 1px 3px 0px;
