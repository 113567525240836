.side-nav-pastelBlue {
  border-right: none;
  background-color: scale-color($pastelBlue-primary-light, $lightness: 85%);
  box-shadow: $pastelBlue-shadow-medium;

  .side-nav-item-pastelBlue {
    &:hover {
      background-color: scale-color($pastelBlue-primary-light, $lightness: 65%);
    }
  }

  .side-nav-item-text-pastelBlue {
    color: $pastelBlue-gray-700;
  }
}
