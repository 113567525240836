.dashboard-item {
	height: 150px;
	padding: 28px;
	min-width: 250px;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.dashboard-circular-progress-container {
	width: 100px;
	margin-left: auto;
}

.stats-box-value {
	font-size: 40px;
	font-weight: 400;
}

.stats-box-label {
	margin-top: 8px;
	font-size: 14px;
}

.dashboard-card-label {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 32px;
}

.chart-legend-entry {
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
	align-items: center;
}

.chart-legend-circle {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	margin-right: 8px;
}

.chart-legend-value {
	font-weight: 500;
	font-size: 16px;
	width: fit-content;
	margin-left: auto;
}