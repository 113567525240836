

.home-spacing-container {
  margin: 16px 0;
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  width: fit-content;
  padding-right: 16px;
}

.home-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 16px 8px;
  text-align: left;
}

.home-title {
  font-size: 24px;
  font-weight: 500;
}

.section-label {
  font-size: 16px;
  margin-top: 16px;
  padding-left: 2px;
  text-align: start;
  color: $gray-900;
}

.modal-buttons-container {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  padding-right: 78px;
  padding-bottom: 32px;
  margin-top: -16px;
}
