// Scss variables (colors etc...)
//
// Custom Color gradients
//
// Color system
//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
//
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// primary
$primary: $blue !default;
// secondary
$secondary: $gray-600 !default;
//
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
//
// Body
//
$body-bg: $white !default;
$body-color: $gray-900 !default;

$primary-color: #292d33;
$secondary-color: #9b9b9b;

$background-color: #e0e0e0;
$primary-text-color: #000000;

$shadow-light: $gray-200 0px 1px 3px 0px;
$shadow-medium: $gray-300 0px 1px 3px 0px;
$shadow-dark: $gray-400 0px 1px 3px 0px;

$side-nav-bar-width: 240px;
$side-nav-bar-width-collapsed: 88px;
$main-nav-bar-height: 90px;

$accent-color: #2d5e41;
$accent-surface: #f7fcf9;
$accent-10: #f2f5f3;
$accent-20: #ceebda;
$accent-30: #86C49F;
$accent-40: #56946F;
$accent-60: #254d35;
$accent-70: #1f402c;

$red-20: #faf2f2;
$red-50: #E36464;
$red-80: #510b0b;

$green-70: #1f402c;

$yellow-20: #f7f0e1;
$yellow-80: #51390b;

$grey: #333333;
$light-grey: #F8FAF9;
$medium-grey: #EDF0EE;
$grey-10: #FAFBFC;
$grey-20: #D7DAE0;
$grey-30: #B0B5BD;
$grey-40: #99A0AD;
$grey-50: #707682;
$grey-60: #494E57;
$grey-70: #292D33;

$peach: #FAF6F0;
$lemon: #FFFFF0;

$secondary-text: rgba(73, 78, 87, 1)

