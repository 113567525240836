// Reusuable classes
.link-text {
	color: blue;
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.error-red {
	color: $red-80;
}

.accent {
	color: $accent-color;
}

.error-text {
	color: $red-80;
	font-size: 14px;
	transition: 0.3s;
	display: flex;
	text-align: left;
	align-items: flex-start;
	overflow-y: hidden;
	gap: 6px;
	margin-top: 6px;
}

.error-text-icon {
	color: $red-50;
	font-size: 18px;
	margin-top: 1.5px;
}
