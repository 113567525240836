// Scss variables (colors etc...)
//
// Custom Color gradients
//
// Color system
//
$pastelBlue-white: #fff !default;
$pastelBlue-gray-100: #f8f9fa !default;
$pastelBlue-gray-200: #e9ecef !default;
$pastelBlue-gray-300: #dee2e6 !default;
$pastelBlue-gray-400: #ced4da !default;
$pastelBlue-gray-500: #adb5bd !default;
$pastelBlue-gray-600: #6c757d !default;
$pastelBlue-gray-700: #495057 !default;
$pastelBlue-gray-800: #343a40 !default;
$pastelBlue-gray-900: #212529 !default;
$pastelBlue-black: #000 !default;
//
$pastelBlue-blue: #aec6cf !default;
$pastelBlue-indigo: #8686af !default;
$pastelBlue-purple: #b19cd9 !default;
$pastelBlue-pink: #ffd1dc !default;
$pastelBlue-red: #ff7770 !default;
$pastelBlue-orange: #ffb347 !default;
$pastelBlue-yellow: #fdfd96 !default;
$pastelBlue-green: #77dd77 !default;
$pastelBlue-teal: #63b7b7 !default;
$pastelBlue-cyan: #b2d8d8 !default;
// primary
$pastelBlue-primary: $pastelBlue-blue !default;
$pastelBlue-primary-dark: #95b5c0 !default;
$pastelBlue-primary-light: #c7d7de !default;
// secondary
$pastelBlue-secondary: #cfbfff !default;
$pastelBlue-secondary-dark: #b5a5e5 !default;
$pastelBlue-secondary-light: #e4dbff !default;
//
$pastelBlue-success: $pastelBlue-green !default;
$pastelBlue-info: $pastelBlue-cyan !default;
$pastelBlue-warning: $pastelBlue-yellow !default;
$pastelBlue-danger: $pastelBlue-red !default;
$pastelBlue-light: $pastelBlue-gray-100 !default;
$pastelBlue-dark: $pastelBlue-gray-900 !default;
//
$pastelBlue-body-bg: $pastelBlue-white !default;
$pastelBlue-body-color: $pastelBlue-gray-900 !default;
//
// shadow
//
$pastelBlue-shadow-light: $pastelBlue-gray-200 0px 1px 3px 0px;
$pastelBlue-shadow-medium: $pastelBlue-gray-300 0px 1px 3px 0px;
$pastelBlue-shadow-dark: $pastelBlue-gray-400 0px 1px 3px 0px;
