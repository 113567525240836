// Scss variables (colors etc...)
//
// Custom Color gradients
//
$moveOn-navbar-bg: linear-gradient(
  180deg,
  rgb(198, 228, 249) 0%,
  rgba(239, 245, 249, 0) 100%
);
// MOVE ON Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
//
//
// Color system
//
// scss-docs-start gray-color-variables
$moveOn-white: #fff !default;
$moveOn-gray-100: #f8f9fa !default;
$moveOn-gray-200: #e9ecef !default;
$moveOn-gray-300: #dee2e6 !default;
$moveOn-gray-400: #ced4da !default;
$moveOn-gray-500: #adb5bd !default;
$moveOn-gray-600: #6c757d !default;
$moveOn-gray-700: #495057 !default;
$moveOn-gray-800: #343a40 !default;
$moveOn-gray-900: #212529 !default;
$moveOn-black: #000 !default;
// scss-docs-end gray-color-variables
//
// scss-docs-start color-variables
$moveOn-blue: #0d6efd !default;
$moveOn-indigo: #6610f2 !default;
$moveOn-purple: #6f42c1 !default;
$moveOn-pink: #d63384 !default;
$moveOn-red: #dc3545 !default;
$moveOn-orange: #fd7e14 !default;
$moveOn-yellow: #ffc107 !default;
$moveOn-green: #198754 !default;
$moveOn-teal: #20c997 !default;
$moveOn-cyan: #0dcaf0 !default;
// Custom Colors
// primary
$moveOn-primary: #7749f8 !default;
$moveOn-primary-dark: #5227cc !default;
$moveOn-primary-light: #ebe5fc !default;
// secondary
$moveOn-secondary: #6c757d !default;
$moveOn-secondary-dark: #54595e !default;
$moveOn-secondary-light: #abb5be !default;
// scss-docs-end color-variables
//
// scss-docs-start theme-color-variables
$moveOn-primary: $moveOn-primary !default;
$moveOn-primary-dark: $moveOn-primary-dark !default;
$moveOn-primary-light: $moveOn-primary-light !default;
$moveOn-secondary: $moveOn-secondary !default;
$moveOn-secondary-dark: $moveOn-secondary-dark !default;
$moveOn-secondary-light: $moveOn-secondary-light !default;
$moveOn-success: $moveOn-green !default;
$moveOn-info: $moveOn-cyan !default;
$moveOn-warning: $moveOn-yellow !default;
$moveOn-danger: $moveOn-red !default;
$moveOn-light: $moveOn-gray-100 !default;
$moveOn-dark: $moveOn-gray-900 !default;
// scss-docs-end theme-color-variables
//
// Body
//
// Settings for the `<body>` element.
$moveOn-body-bg: $moveOn-white !default;
$moveOn-body-color: $moveOn-gray-900 !default;
