.button {
	background: #dddddd;
	border: none;
	font-size: 12px;
	border-radius: 4px;
	height: 36px;
	padding-left: 16px;
	padding-right: 16px;
	font-weight: 400;
	color: #000000;
	transition: 0.3s;
	&:focus {
		outline: none;
	}
	&:hover {
		opacity: 0.8;
	}
}

.text-button {
	color: $grey;
	font-size: 14px;
	font-weight: 600;
	user-select: none;
	cursor: pointer;
	position: relative;
}

.primary-button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	position: relative;
	border: none;
	border-radius: 8px;
	font-size: 14px;
	padding-left: 16px;
	padding-right: 16px;
	cursor: pointer;
	background-color: $accent-color;
	color: white;
	font-weight: 500;
	user-select: none;
	&:hover {
		background-color: $accent-60;
	}
	&:active {
		background-color: $accent-70;
	}
}

.primary-button:disabled {
	background-color: $grey-40 !important;
	color: $grey-20 !important;
	cursor: default;
	pointer-events: none;
}

.secondary-button:disabled {
	color: $grey-30;
	border: 1px solid $grey-30;
	cursor: default;
	pointer-events: none;
}

.secondary-button {
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: $accent-color;
	box-sizing: border-box;
	border: 1px solid $accent-color;
	border-radius: 8px;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 14px;
	user-select: none;
	background-color: transparent;

	&:hover {
		background-color: $accent-10;
	}
	&:active {
		background-color: $accent-20;
	}
}

.text-button {
	font-weight: 500;
	border: none;
}

.icon-button {
	color: $accent-color;
	border: 1px solid $accent-color;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	background-color: white;
	outline: none !important;
	position: relative;
	&:hover {
		background-color: $accent-10;
	}
	&:active {
		background-color: $accent-20;
	}
}

.icon-button-secondary {
	outline: 1px solid $grey-20;
	border-radius: 4px;
	padding: 9px;
	display: flex;
	color: $grey-50;
	position: relative;
	background-color: transparent;
	box-sizing: border-box;
	border: none;
	&:hover {
		outline: 2px solid $grey-30 !important;
	}
	&:active {
		outline: 2px solid $grey-40 !important;
		background-color: $accent-10;
	}
}

.filter-button {
	min-width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $grey-20;
	border-radius: 4px;
	cursor: pointer;
}

.button-group-button {
	font-size: 14px;
	color: $grey-50;
	background-color: white;
	border: 1px solid $grey-20;
	box-shadow: none !important;

	&:hover {
		background-color: white;
		color: $accent-color;
		border: 1px solid $grey-20;
	}
	&:focus {
		border: 1px solid $grey-20;
		background-color: white;
		color: $accent-color;
	}
}

.button-group-button-selected {
	font-weight: 600;
	color: $accent-color;
	background-color: $accent-surface !important;
}

/** Overrides default bootstrap button styles **/
.btn {
	border-radius: 8px;
}

.back-button {
	display: flex;
	align-items: center;
	color: $grey-50;
	cursor: pointer;
}

.button-loading-spinner-overlay {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-check-input:checked {
	background: $accent-40;
	border-color: $accent-color;
}

.form-check-input {
	width: 16px;
	height: 16px;
}

.form-check-label {
	height: 16px;
	font-size: 14px;
}

.form-check {
	display: flex;
	align-items: center;
	gap: 8px;
}