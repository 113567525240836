.page-container {
	// padding-left: 20px;
	margin-left: calc($side-nav-bar-width + 60px);
	margin-right: 60px;

	margin-top: calc($main-nav-bar-height + 20px);
	transition: 0.3s;
}

.page-container-collapsed-nav {
	margin-left: calc($side-nav-bar-width-collapsed + 60px) !important;
}

.page-container-inner {
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	border-radius: 12px;
}


