.divider {
    border-bottom: 1px solid #b8b8b8;
}

.search-page-drawer {
    background: rgba(235, 232, 232, 0.5);
    height: 95vh;
    padding-bottom: 32px;
    width: 100%;
    overflow-y: scroll;
}

.search-page-drawer-item {
    padding: 24px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid white;
    text-align: left;
    &:hover {
        background: rgba(235, 232, 232, 0.2);
        cursor: pointer;
    }
}

.search-page-drawer-item-title {
    font-size: 14px;
    font-weight: 600;
}

.item-property {
    font-size: 12px;
    color: #777777;
    margin-right: 8px;
}

.item-element-container {
    width: calc(50% - 10px);
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 12px;
    font-size: 14px;
    text-align: start;
    overflow-wrap: break-word;
}

.item-element-name {
    color: $grey-50;
    margin-bottom: 6px;
    font-size: 12px;
}

.item-element-value {
    color: $grey-70;
    font-weight: 500;
    font-size: 14px;
}

.search-page-item-title {
    font-size: 20px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: left;
    max-width: 100%;
    word-break: break-all;
}