.settings-section {
	margin-bottom: 60px;
}

.settings-section-label {
	font-weight: 700;
	font-size: 16px;
	text-align: left;
	margin-bottom: 20px;
}
