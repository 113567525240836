.button-pastelOrange {
  background-color: $pastelOrange-primary-dark;
  // border: none;
  // font-size: 12px;
  // border-radius: 4px;
  // height: 38px;
  // padding-left: 16px;
  // padding-right: 16px;
  // font-weight: 400;
  color: $pastelOrange-gray-900;
  transition: background-color 0.3s ease-in-out;
  &:focus {
    outline: none; // needs to be fixed with a global css reset
  }
  &:hover {
    background-color: scale-color($pastelOrange-primary-dark, $lightness: 20%);
    opacity: 1; // needs to be fixed in the default
  }
}
