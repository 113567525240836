.main-nav {
	z-index: 3;
	position: fixed;
	top: 0;
	left: $side-nav-bar-width;
	background-color: $accent-10;
	width: calc(100% - $side-nav-bar-width);
	height: $main-nav-bar-height;
	padding-top: 24px;
	padding-bottom: 16px;
	padding-left: 60px;
	padding-right: 60px;

	box-sizing: border-box;
	transition: 0.3s;
}

.main-nav-inner {
	width: 100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.main-nav-collapsed-side {
	width: calc(100% - $side-nav-bar-width-collapsed);
	left: $side-nav-bar-width-collapsed;
}

.main-nav-title {
	color: $accent-color;
	font-size: 24px;
	font-weight: 700;
}

.main-nav-right {
	width: fit-content;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.nav-user-info {
	user-select: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 12px;
	border-radius: 8px;
	text-align: end;
	cursor: pointer;
	gap: 12px;

	&:hover {
		background-color: white;
	}
}

.nav-user-photo {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-left: 12px;
	background: #888888;
}

.nav-username {
	font-size: 14px;
}

.nav-bar-icon-button {
	&:hover {
		background-color: white;
	}
	border-radius: 8px;
	padding: 12px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
