// Scss variables (colors etc...)
//
// Custom Color gradients
//
// Color system
//
$pastelGreen-white: #fff !default;
$pastelGreen-gray-100: #f8f9fa !default;
$pastelGreen-gray-200: #e9ecef !default;
$pastelGreen-gray-300: #dee2e6 !default;
$pastelGreen-gray-400: #ced4da !default;
$pastelGreen-gray-500: #adb5bd !default;
$pastelGreen-gray-600: #6c757d !default;
$pastelGreen-gray-700: #495057 !default;
$pastelGreen-gray-800: #343a40 !default;
$pastelGreen-gray-900: #212529 !default;
$pastelGreen-black: #000 !default;
//
$pastelGreen-blue: #aec6cf !default;
$pastelGreen-indigo: #8686af !default;
$pastelGreen-purple: #b19cd9 !default;
$pastelGreen-pink: #ffd1dc !default;
$pastelGreen-red: #ff7770 !default;
$pastelGreen-orange: #ffb347 !default;
$pastelGreen-yellow: #fdfd96 !default;
$pastelGreen-green: #77dd77 !default;
$pastelGreen-teal: #63b7b7 !default;
$pastelGreen-cyan: #b2d8d8 !default;
// primary
$pastelGreen-primary: $pastelGreen-green !default;
$pastelGreen-primary-dark: #67d967 !default;
$pastelGreen-primary-light: #96e596 !default;
// secondary
$pastelGreen-secondary: #cfbfff !default;
$pastelGreen-secondary-dark: #b5a5e5 !default;
$pastelGreen-secondary-light: #e4dbff !default;
//
$pastelGreen-success: $pastelGreen-green !default;
$pastelGreen-info: $pastelGreen-cyan !default;
$pastelGreen-warning: $pastelGreen-yellow !default;
$pastelGreen-danger: $pastelGreen-red !default;
$pastelGreen-light: $pastelGreen-gray-100 !default;
$pastelGreen-dark: $pastelGreen-gray-900 !default;
//
$pastelGreen-body-bg: $pastelGreen-white !default;
$pastelGreen-body-color: $pastelGreen-gray-900 !default;
//
// shadow
//
$pastelGreen-shadow-light: $pastelGreen-gray-200 0px 1px 3px 0px;
$pastelGreen-shadow-medium: $pastelGreen-gray-300 0px 1px 3px 0px;
$pastelGreen-shadow-dark: $pastelGreen-gray-400 0px 1px 3px 0px;
