.red-stylesheet-dark {
  color: #ff5733;
  font-weight: bold;
  text-align: center;
}

.yellow-stylesheet-dark {
  color: #e38917;
  text-align: center;
  font-weight: bold;
}

.simplified-declaration-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DimGray;
  border-style: solid;
}

.deferred-payment-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.contacts-dark {
  background-color: #030202;
  border-width: 4px;
  border-color: DimGray;
  border-style: solid;
}

.warehouse-identification-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: SlateGray;
  border-style: solid;
}

.red-border-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: red;
  border-style: solid;
}

.loading-location-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.location-of-goods-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.customs-offices-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.border-transport-means-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.calculation-of-taxes-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.tab-3-border-colour-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.declaration-section-dark {
  background-color: I929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.total-amount-invoiced-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.identity-of-means-transport-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: DarkSlateGray;
  border-style: solid;
}

.guarantee-reference-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: #616161;
  border-style: solid;
}

.container-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: #616161;
  border-style: solid;
}

.black-white-dark {
  background-color: #929292;
  border-width: 4px;
  border-color: black;
  border-style: solid;
}
