.inputs-section {
	border: 1px solid $grey-20;
	border-radius: 12px;
	padding: 20px;
}

.inputs-section-title {
	font-weight: 700;
	font-size: 16px;
	color: $accent-color;
	text-align: left;
}

.input-label {
	color: $grey-60;
	font-size: 14px;
}

.input {
	width: 100%;
	padding: 8px;
	height: 36px;
	font-size: 14px;
	background-color: white;
	border-radius: 4px;
	border: 1px solid $grey-20;
	box-sizing: border-box;
}

.input-error {
	border: 1px solid $red-50;
}

.input:disabled,
.text-area:disabled {
	opacity: 0.5;
}

.input:hover {
	outline: 2px solid $accent-30;
}

.input:focus {
	outline: 2px solid $accent-40;
}

.text-area {
	width: 100%;
	padding: 8px;
	font-size: 14px;
	background-color: white;
	border-radius: 4px;
	border: 1px solid $grey-20;
	min-height: 74px;
}

.text-area-error {
	border: 1px solid $red-50;
}

.peach {
	background-color: $peach;
}

.lemon {
	background-color: $lemon;
}

.light-grey {
	background-color: $light-grey;
}

.medium-grey {
	background-color: $medium-grey;
}
.tab-3-border-colour {
	background-color: #f8fbff;
	box-shadow: #767a7e 0px 1px 3px 0px;
}

.table-inputs-container {
	display: flex;
	column-gap: 40px;
	row-gap: 20px;
	flex-wrap: wrap;
}

.table-container {
	border: 1px solid $grey-20;
	background-color: white;
	border-radius: 6px;
	overflow-y: scroll;
}

.table {
}

.table-head {
	vertical-align: top !important;
}

th {
	border-bottom-color: $grey-20 !important;
	font-size: 14px;
	color: $accent-color;
	font-weight: 600;
	text-align: left;
}

td {
	font-size: 14px;
	color: $grey-60;
	text-align: left;
}

tr:nth-child(even) {
	background-color: $grey-10;
}

.empty-table-message {
	font-size: 14px;
	color: $grey-30;
	margin-top: 12px;
}
